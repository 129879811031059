<template>
  <div class="content">
    <div class="top">
      <!-- <h1>我向校长说句话</h1> -->
      <!-- <p class="introduce">我向校长说句话</p> -->
    </div>
    <div class="main">
      <div class="tableContent">
        <div class="mainTable">
          <!-- <div class="tableName">
            <span>我要留言</span>
          </div> -->
          <el-form
            :rules="rules"
            status-icon
            class="froms"
            ref="ruleForm"
            label-position="top"
            label-width="80px"
            :model="ruleForm">
            <el-form-item label="名字" prop="name" class="three-ipt el-form-item">
              <el-input
                class="nameInput"
                v-model="ruleForm.name"
                placeholder="您的姓名"
                disabled></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email" class="three-ipt el-form-item">
              <el-input
                class="email"
                v-model="ruleForm.email"
                @input="verifyEmail"
                placeholder="请输入邮箱"
                disabled>
                <!-- <el-button v-if="emailState == '0'" @click="sendVerifyCode" slot="append">
                  发送验证码</el-button
                >
                <el-button v-if="emailState == '2'" @click="sendVerifyCode" slot="append"
                  >重新发送</el-button
                >
                <template v-if="emailState == '1'" slot="append">{{ verifyCodeWaitTime }}</template> -->
              </el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone" class="three-ipt el-form-item">
              <el-input class="phone" v-model="ruleForm.phone" placeholder="请输入手机号" disabled>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="反馈类型" prop="messageType" class="messageType el-form-item">
              <el-autocomplete
                class="inline-input"
                v-model="ruleForm.messageType"
                :fetch-suggestions="getAllMessageType"
                placeholder="可自定义">
              </el-autocomplete>
            </el-form-item> -->
            <el-form-item label="反馈类型" prop="messageType" class="messageType el-form-item">
              <el-select v-model="ruleForm.messageType" placeholder="请选择">
                <el-option
                  v-for="item in messageTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标题" prop="title" class="title-ipt el-form-item">
              <el-input
                maxlength="30"
                show-word-limit
                v-model="ruleForm.title"
                placeholder="请输入标题"></el-input>
            </el-form-item>

            <el-form-item label="内容" prop="content" class="write-content">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleForm.content"
                maxlength="1000"
                rows="5"
                resize="none"
                show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item
              v-if="emailState == '1' || emailState == '2'"
              label="验证码"
              prop="verifyCode">
              <el-input
                class="email"
                v-model="ruleForm.verifyCode"
                placeholder="请输入验证码"></el-input>
            </el-form-item>
          </el-form>
          <div class="tips" style="display: flex;">
            <b style="display: inline-block; width: 62px;">提示：</b>
            <div>
              <p>
                1.为了你的留言得到及时有效的处理和回应，请填写真实信息，凡未注明留言人姓名及电子邮箱等真实有效联系方式的来信，仅作一般建议参考，均不予回复。
              </p>
              <p>
                2.反映问题须客观真实，注意文明用语，凡出现有侮辱人格、恶意攻击、不健康及其他法律禁止的内容，将直接删除，情节严重的转送公安机关依法处理。
              </p>
              <p>3.校长信箱不受理应当通过诉讼、仲裁等法定途径解决的问题。</p>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" @click="jumpHome" class="sendBtn">返回首页</el-button>
            <el-button type="success" @click="sendMessageContent" class="sendBtn">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {userMessage} from '../service/getData';
export default {
  name: 'SendMialbox',
  data() {
    return {
      ruleForm: {
        title: '',
        content: '',
        name: '',
        email: '',
        verifyCode: '',
        messageType: '',
        phone: ''
      },
      messageTypeOptions: [],
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur'}
        ],
        content: [{required: true, message: '具体内容', trigger: 'blur'}],
        phone: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入合法手机号', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '您的姓名', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
        messageType: [
          {required: true, message: '不可为空', trigger: 'change'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        verifyCode: [{required: true, message: '请输入收到的验证码', trigger: 'blur'}]
      },
      //-1 邮箱未验证, 0 邮箱已验证, 1 验证码已发送,冷却期 2 验证码可重新发送
      emailState: '-1',
      verifyCodeWaitTime: 0
    };
  },
  mounted() {
    this.getAllMessageType();
    userMessage().then((values) => {
      console.log(values);
      if (values.code === 200) {
        this.ruleForm.name = values.object.nickname;
        this.ruleForm.email = values.object.email;
        this.ruleForm.phone = values.object.phoneNumber;
      } else {
        this.$message({
          type: 'error',
          message: values.message
        });
      }
    });
  },
  methods: {
    jumpHome() {
      this.$router.push({
        name: 'IntroducePage'
      });
    },
    async sendVerifyCode() {
      const flag = '@' + this.ruleForm.email.split('@')[1];
      try {
        let data = await axios({
          url: '/mailbox/Visitor/Reception/sendEmail',
          method: 'POST',
          headers: {token: this.$store.state.token},
          params: {
            email: this.ruleForm.email.split('@')[0],
            flag
          }
        });
        this.emailState = '1';
        console.log(data);
        this.verifyCodeWaitTime = 60;
        const siv = setInterval(() => {
          if (this.verifyCodeWaitTime > 0) {
            this.verifyCodeWaitTime--;
          } else {
            this.emailState = '2';
            clearInterval(siv);
          }
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    verifyEmail() {
      this.$refs.ruleForm.validateField('email', (err) => {
        if (err === '' && this.emailState === '-1') {
          this.emailState = '0';
        }
      });
    },
    async sendMessageContent() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            messageContent: this.ruleForm.content,
            messageTitle: this.ruleForm.title,
            messageType: this.ruleForm.messageType,
            touristEmail: this.ruleForm.email,
            touristName: this.ruleForm.name,
            touristPhone: this.ruleForm.phone
          };
          params = Object.entries(params).reduce((pre, cur) => {
            if (cur[1] !== '') {
              pre[cur[0]] = cur[1];
            }
            return pre;
          }, {});
          try {
            this.$confirm('确定提交信件吗', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              axios({
                url: '/mailbox/addAMessage',
                headers: {token: this.$store.state.token},
                method: 'POST',
                params: params
              }).then((data) => {
                console.log('1111111111');
                console.log(data);
                if (data.code === 200) {
                  this.$router.push({
                    name: 'IntroducePage'
                  });
                  this.$message({
                    message: '留言成功',
                    type: 'success'
                  });
                } else {
                  this.$message({
                    message: data.message,
                    type: 'error'
                  });
                }
              });
            });
          } catch (err) {
            console.log(err);
            this.$message({
              message: err,
              type: 'error'
            });
          }
        } else {
          this.$message({
            message: '请确定所有内容是否填写和格式是否正确',
            type: 'error'
          });
          return false;
        }
      });
      // });
    },
    async getAllMessageType() {
      try {
        let data = await axios({
          url: '/mailbox/Visitor/Reception/findAllTypes',
          headers: {token: this.$store.state.token},
          method: 'GET'
        });
        console.log(data);
        let tmpArr = data.object.map((item) => {
          return {value: item.typeName, label: item.typeName};
        });
        console.log(tmpArr);
        // for (let i = 0; i < tmpArr.length; i++) {
        //   for (let j = i; j < tmpArr.length; j++) {
        //     if (tmpArr[i].value === tmpArr[j].value) {
        //       tmpArr.splice(j, 1);
        //     }
        //   }
        // }
        // console.log(tmpArr);
        this.messageTypeOptions = tmpArr;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.write-content {
  width: 96%;
  margin-bottom: 0;
}
.content {
  margin-top: 90px;
  // padding: 5% 5%;
  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }
  input {
    height: 30px;
  }
  .top {
    .introduce {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
    }
  }
  .tips {
    margin-top: 20px;
    p {
      // margin-top: 20px;
      font-size: 14px;
      line-height: 30px;
    }
  }
  .main {
    .tableContent {
      .mainTable {
        .froms {
          //   overflow: hidden;
          position: relative;
          min-width: 30%;
          .three-ipt {
            width: 30%;
            float: left;
            margin-right: 3%;
          }
          .messageType {
            width: 30%;
            float: left;
            margin-right: 3%;
            .inline-input {
              width: 100%;
            }
          }
          .title-ipt {
            width: 63%;
            float: left;
          }
        }
      }
    }
  }
}
.bottom-btn {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.el-form-item {
  margin-bottom: 0px;
}
/deep/ .el-form-item__label {
  padding-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .content {
    padding: 5% 5%;
    .main {
      .tableContent {
        .mainTable {
          flex-direction: column;
          .froms {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
</style>
